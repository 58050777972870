export default {
  tableFields: [
    {
      name: '__component:badge-column',
      title: '',
      dataClass: 'text-center',
      width: '4%'
    },
    {
      name: 'name',
      title:'Nombre',
      sortField: 'name',
    //  width: '25%'
    },

    {
      name: 'user_ktag.codektag',
      title:'Ktag',
      sortField: 'user_ktag.codektag',
    //  width: '25%'
    },
    {
      name: 'email',
      title:'Correo',
      sortField: 'email',
    //  width: '25%'
    },
    {
      name: 'code',
      title:'Código',
      sortField: 'code',
    //  width: '25%'
    },
   /* {
      name: 'instagram',
      sortField: 'instagram',
    //  width: '25%'
    },
    {
      name: 'facebook',
      sortField: 'facebook',
      width: '20%'
    },
    {
      name: 'twitter',
      sortField: 'twitter',
     // width: '25%'
    },*/
    {
      name: 'type_customer.name',
      title: 'Tipo de cliente',
      width: '20%'
    },
    {
      name:       '__slot:active',
      title:      'Estatus',
      titleClass: "center aligned",
      dataClass:  "center aligned",
      width:      "20%",
    }, {
      name:       '__slot:actions',
      title:      'Acciones',
      titleClass: "center aligned",
      dataClass:  "center aligned",
      width:      "20%",
    }
  ],
  sortFunctions: {
    'name': function (item1, item2) {
      return item1 >= item2 ? 1 : -1
    },
    'email': function (item1, item2) {
      return item1 >= item2 ? 1 : -1
    }
  }
}
