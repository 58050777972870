<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget>
          <div class="row">
            <div class="col-md-4">
              <div class="card h-200">
                <img
                  class="card-img-top"
                  src="@/assets/images/analytics-dashboard.png"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title">Tablero General</h5>
                  <p class="card-text">Detalle General de Analíticas</p>
                  <a
                    @click="goToReport('dashboard')"
                    class="btn btn-primary btn-micro"
                    >Ir al Reporte</a
                  >
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card h-200">
                <img
                  class="card-img-top"
                  src="@/assets/images/analytics-ubication.jpg"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title">Analíticas por Ubicación</h5>
                  <p class="card-text">Según las ubicaciones del evento</p>
                  <a
                    @click="goToReport('ubication')"
                    class="btn btn-primary btn-micro"
                    >Ir al Reporte</a
                  >
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card h-200">
                <img
                  class="card-img-top"
                  src="@/assets/images/analytics-money.jpg"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title">Analíticas por Día</h5>
                  <p class="card-text">Según los días del evento</p>
                  <a
                    @click="goToReport('day')"
                    class="btn btn-primary btn-micro"
                    >Ir al Reporte</a
                  >
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-4">
              <div class="card h-200">
                <img
                  class="card-img-top"
                  src="@/assets/images/analytics-money.jpg"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title">Analíticas de Dinero</h5>
                  <p class="card-text">Seguimiento de Valores</p>
                  <a
                    @click="goToReport('money')"
                    class="btn btn-primary btn-micro"
                    >Ir al Reporte</a
                  >
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card h-200">
                <img
                  class="card-img-top"
                  src="@/assets/images/analytics-credit.jpg"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title">Créditos</h5>
                  <p class="card-text">
                    Seguimiento segun los Tipos de Crédito
                  </p>
                  <a
                    @click="goToReport('credit')"
                    class="btn btn-primary btn-micro"
                    >Ir al Reporte</a
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <img
                  class="card-img-top"
                  src="@/assets/images/download.jpg"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title">Descargas</h5>
                  <p class="card-text">Descarga distintos reportes</p>
                  <a
                    @click="goToReport('download')"
                    class="btn btn-primary btn-micro"
                    >Descargar Reportes</a
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <img
                  class="card-img-top"
                  src="@/assets/images/download.jpg"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title">Visor Protaps</h5>
                  <p class="card-text">Accede al visor público de analíticas</p>
                  <a
                    @click="goToVisor('download')"
                    class="btn btn-primary btn-micro"
                    >Ir al visor</a
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <img
                  class="card-img-top"
                  src="@/assets/images/download.jpg"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title">Total ventas excel</h5>
                  <p class="card-text">Un excel detallado por estación y total ventas de productos</p>
                  <a
                    @click="downloadTotalExcel('download')"
                    class="btn btn-primary btn-micro"
                    >Descargar Excel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import TableCustomer from '../tables/TableCustomer/TableCustomer.vue'

import { SpringSpinner } from 'epic-spinners'
import Cookies from 'js-cookie'
import axios from 'axios'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Table',
  components: {
    SpringSpinner,
    TableCustomer,
  },
  data () {
    return {}
  },
  methods: {
    ...mapActions([
      'loadTotalExcel',
    
    ]),
    
    back () {
      // this.$router.go(-1);
      this.$router.push('/')
    },
    goToReport (url) {
      this.$router.push('analytics/' + url)
    },
    goToVisor (url) {
      //get event from cookies
      //

      let event = Cookies.get('event')
      let eventData = JSON.parse(event);
      console.log(eventData)
      console.log(eventData.code)

      //Conver event code to base64
      let base64code = btoa(eventData.code)
      console.log(base64code)
      //Go to new tab with new url
      window.open('http://visor.protaps.app/' + base64code+'/home', '_blank')
     // this.$router.push('analytics/' + url)
    },
    downloadTotalExcel (url) {
      //get event from cookies
      //

      let event = Cookies.get('event')
      let eventData = JSON.parse(event);
      console.log(eventData)
      console.log(eventData.code)

      let form = {
        id_event: eventData.id,
      }
      //make axios petition post to download excel
      this.loadTotalExcel(form).then((data) => {
      
        console.log(data)
        //Open new tab
        window.open(
          data.data.url_file,
          '_blank'
        )
      })
    },
  },
}
</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>
